import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO, { createSEO } from 'components/Core/SEO';
import WebsiteLayout from 'layouts/websiteLayout';
import ContentfulDynamicHero from 'components/Contentful/ContentfulDynamicHero';
import ContentfulRowContent from 'components/Contentful/ContentfulRowContent';
import ContentfulCardsBlock from 'components/Contentful/ContentfulCardsBlock';

const IndustriesPage = (props) => {
  const { components } = props.data.contentfulComponentCollection;

  const fetchCards = (filter) => components.find((ele) => ele.slug === filter);
  const fetchEntries = (filter) => components.find((ele) => ele.__typename === filter);

  const seo = createSEO(fetchEntries('ContentfulSeo'), props);

  return (
    <WebsiteLayout hero={{ background: '#00A9F7' }} layout="industry">
      <SEO {...seo} />
      <ComponentsContainer>
        <ContentfulDynamicHero {...fetchEntries('ContentfulDynamicHero')} />

        <ContentfulCardsBlock {...fetchCards('Featured Industries Cards')} />

        <ContentfulCardsBlock {...fetchCards('Other Industries Cards')} />

        <ContentfulRowContent {...fetchEntries('ContentfulRowContent')} />
      </ComponentsContainer>
    </WebsiteLayout>
  );
};

export default IndustriesPage;

export const IndustriesPageQuery = graphql`
  query IndustriesPageQuery {
    contentfulComponentCollection(contentful_id: { eq: "4n3Ocmy8Fs81Y097E7K3Zh" }) {
      ...ComponentCollection
    }
  }
`;

const ComponentsContainer = styled.div`
  .section-layout--container:first-child {
    .section-layout--inner-container {
      max-width: 830px;
      padding-bottom: 140px;
      padding-top: 35px;
      .hero-contextual-cta--origin {
        width: 100%;
      }
    }
  }
  .section-industry-card--container {
    padding: 0px 20px 91px;
  }
  .Featured.Industries.Cards {
    background: transparent;
    margin-top: -130px;

    .card--body-wrapper.icon-card--body-wrapper {
      .icon-card--icon-container {
        .icon-card--image {
          width: 40px;
          height: 40px;

          @media (max-width: 767px) {
            width: 30px !important;
            height: 30px !important;
            min-width: 30px !important;
          }
        }
      }
    }
    .icon-card-footer {
      @media (max-width: 575px) {
        left: 74px;
      }
    }
  }
  .Other.Industries.Cards {
    .section-industry-card--title {
      font-size: var(--font-size-18);
      max-width: inherit;
      text-align: left;
    }
    .section-industry-card--card-content {
      margin-top: 20px;
    }
    .card--body-wrapper.icon-card--body-wrapper {
      padding-bottom: 30px;
    }
    .icon-card-footer {
      @media (max-width: 575px) {
        left: 44px;
      }
    }
  }
`;
